import { _rootApi } from "../../../web/global/Api/_root"
import { ApiResultDataSingle } from "./OrdersSearch";

export interface ApiParams {
  dt_from: string,
  dt_to: string
}

export interface ApiResultBestSeller {
  id: number;
  price: number;
  total: number;
  quantity: number;
  name: string;
  category: string;
  link_rewrite: string;
  imgs: Array<{
    id: number;
    desciption: string;
  }>
}

export interface ApiResultMetricsData {
  sales: string;
  orders: string;
  cart_value: string;
  visits: string;
  conversion_rate: string;
  net_profit: string;
}

export interface ApiResult {
  last_orders: Array<ApiResultDataSingle>;
  best_sellers: Array<ApiResultBestSeller>;
  metrics_data: ApiResultMetricsData,
  sales_per_day: {[index:string]:number};
}

export class ApiAdminHome extends _rootApi{
  static request(params:ApiParams, func_success?: (v:ApiResult) => void, func_error?: () => void):void{
    this._request({
        url: '/home',
        params: params as unknown as {[index:string]:string},
        func_success: (r:ApiResult) => {
            if(func_success) func_success(r);
        },
        func_error: () => {
            if(func_error) func_error();
        }
    });
  }
}